import Vue from 'vue';
import CommonPlugin from 'plugins/common-plugin';
import { i18n } from 'utils/i18n';
import store from 'stores/generic';

Vue.use(CommonPlugin);

require('styles/pages/page.less');

new Vue({
  el: '#app',
  i18n,
  store,
  components: {
    'login-page': () => import(/* webpackChunkName: "loginPage" */ 'views/LoginPage.vue')
  }
});
